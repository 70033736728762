import React, { useLayoutEffect } from 'react';

import { setColors } from '../libs/utils';

import AppHeader from '../components/AppHeader.jsx';

import PageBreadcrumb from '../ui/Breadcrumb.jsx';

import Select from 'react-select';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

function AppUI() {

    useLayoutEffect(() => {
        setColors();
    });

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]

    return(
        <div className="App">
            <AppHeader />
            <section className="App-section">
                <Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
                            <h2>UI de l'application</h2>
                            <PageBreadcrumb page="ui" />
                        </div>
				</Stack>
                <div>
                    <h3>Formulaires</h3>
                    <h4>Select</h4>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select</Form.Label>
                            <Select 
                                placeholder="Voici un select"
                                options={options}
                            />
                        </Form.Group>
                    </Form>
                    <h4>Inputs</h4>
                    <h5>Types</h5>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Texte)</Form.Label>
                            <Form.Control 
                                type="text"
                                placeholder="Voici un input type texte" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Nombre)</Form.Label>
                            <Form.Control 
                                type="number" min="0" step="0.01"
                                placeholder="Voici un input type nombre" 
                            />
                        </Form.Group>
                    </Form>
                    <h5>Tailles</h5>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Large)</Form.Label>
                            <Form.Control 
                                type="text" size="lg"
                                placeholder="Voici un input large" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Normal)</Form.Label>
                            <Form.Control 
                                type="text"
                                placeholder="Voici un input normal" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Small)</Form.Label>
                            <Form.Control 
                                type="text" size="sm"
                                placeholder="Voici un input small" 
                            />
                        </Form.Group>
                    </Form>
                    <h5>États</h5>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Disabled)</Form.Label>
                            <Form.Control 
                                type="text" disabled
                                placeholder="Voici un input disabled" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Read-only)</Form.Label>
                            <Form.Control 
                                type="text" readOnly
                                placeholder="Voici un input read-only" 
                            />
                        </Form.Group>
                    </Form>
                    <h5>Validation</h5>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Valid)</Form.Label>
                            <Form.Control 
                                type="text" isValid="true"
                                placeholder="Voici un input valid" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Input (Invalid)</Form.Label>
                            <Form.Control 
                                type="text" isInvalid="true"
                                placeholder="Voici un input invalid" 
                            />
                        </Form.Group>
                    </Form>
                </div>
            </section>
        </div>
    );
}

export default AppUI;