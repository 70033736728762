import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from "react-router-dom";
import { toDataURL } from 'qrcode';

import { fetchCategoryObjects } from '../services/inventory';
import { setColors } from '../libs/utils';

import AppHeader from '../components/AppHeader.jsx';
import CategoryTable from '../components/CategoryTable.jsx';

import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import PageBreadcrumb from '../ui/Breadcrumb';

function CategoryView() {

    const params = useParams();

    const [objects, setObjects] = useState([]);
    const [categoryName, setCategoryName] = useState("");
	const [objectsCount, setObjectsCount] = useState(0);
	const [QRsource, setQRsource] = useState("");

	useLayoutEffect(() => {
	  	setColors();
	});

    useEffect(() => {
		if(params.name) {
			setCategoryName(params.name);
			fetchCategoryObjects(params.name).then((response) => {
				setObjects(response.result);
				setObjectsCount(response.result.length);
			});
			const url = window.location+"";
        	toDataURL(url).then(setQRsource);
		};

	},[params.name]);

    return (
        <>
            <div className="App">
			<AppHeader />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2>{categoryName}</h2>
							<PageBreadcrumb page="category" />
						</div>
						<p className='ms-auto'><Badge bg="primary" text="black" className='ms-2'>{objectsCount}</Badge> objet{objectsCount > 1 && `s`} dans cette catégorie</p>
					</Stack>
                    <CategoryTable objects={objects} setObjects={setObjects} />
					<div className="d-none d-sm-block">
                		<img src={QRsource} alt="QR Code" />
            		</div>
                </section>
            </div>
            
        </>
    )

}

export default CategoryView;